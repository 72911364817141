import React from 'react'

export const CommonRoomScript = () => {
    const mode = process.env.NODE_ENV

    React.useEffect(() => {
        const scriptElement = document.createElement('script')

        scriptElement.textContent =
            mode === 'production'
                ? `
                (function() {
                    if (typeof window === 'undefined') return;
                    if (typeof window.signals !== 'undefined') return;
                    var script = document.createElement('script');
                    script.src = 'https://cdn.cr-relay.com/v1/site/1f878624-8842-46c2-b5c5-5aa116d51456/signals.js';
                    script.async = true;
                    window.signals = Object.assign(
                        [],
                        ['page', 'identify', 'form'].reduce(function (acc, method){
                            acc[method] = function () {
                                signals.push([method, arguments]);
                                return signals;
                            };
                            return acc;
                        }, {})
                    );
                    document.head.appendChild(script);
                })();
                `
                : `
                (function() {
                console.log('[Mock Common Room] - Script initialized in ${mode} mode');
                })();
                `

        document.body.appendChild(scriptElement)

        return () => {
            document.body.removeChild(scriptElement)
        }
    }, [])

    return null
}
